<template>
  <div :class="wrapperClasses">
    <ImageRenderer
      v-if="image"
      :alt="image?.alt"
      class="flex h-full justify-center"
      :height="image?.height"
      :height-mobile="image?.heightMobile"
      :loading="Loading.Eager"
      :src="image?.src"
      :src-mobile="image?.srcMobile"
      :width="image?.width"
      :width-mobile="image?.widthMobile"
    />

    <RevContainer
      class="absolute bottom-0 w-full pb-32 lg:pb-72"
      :class="isBigBanner ? 'pt-[6.5rem]' : 'pt-[5.5rem]'"
    >
      <div
        class="absolute bottom-0 left-0 h-full w-full"
        :class="hasWhiteText ? $style.blackBlur : $style.whiteBlur"
      />

      <div
        class="absolute bottom-0 left-0 size-full"
        :class="hasWhiteText ? $style.blackGradient : $style.whiteGradient"
        data-test="hero-banner-overlay"
      />

      <div class="relative">
        <div class="md:max-w-[736px]">
          <Heading
            :badge
            :size="isBigBanner ? 'large' : 'medium'"
            :text-color
            :title
          />

          <RichTextVueParser
            v-if="isBigBanner && subtitle"
            class="mt-4"
            :class="[
              getBannerTextColorClass(textColor),
              relatedLinks ? 'line-clamp-3 md:line-clamp-5' : '',
            ]"
            :default-style="false"
            :rich-text="subtitle"
          />
        </div>

        <RelatedLinks
          v-if="isBigBanner && relatedLinks"
          :align="relatedLinks.align"
          class="mt-32"
          :display="relatedLinks.display"
          :links="relatedLinks.links"
          :size="relatedLinks.size"
        />
      </div>
    </RevContainer>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import {
  type Banner,
  BannerTextColor,
} from '@backmarket/http-api/src/api-specs-content/models/banner'
import { Loading } from '@backmarket/nuxt-layer-cms/ImageRenderer.constants'
import ImageRenderer from '@backmarket/nuxt-layer-cms/ImageRenderer.vue'
import RelatedLinks from '@backmarket/nuxt-layer-cms/RelatedLinks.vue'
import RichTextVueParser from '@backmarket/nuxt-layer-cms/RichTextVueParser.vue'
import { tw } from '@backmarket/utils/string/tw'
import { RevContainer } from '@ds/components/Container'

import { getBannerTextColorClass } from '../helpers/banner/getTextColor'
import Heading from '../shared-components/Heading/Heading.vue'

type BannerProps = Banner & { badge: string; title: string }

const props = withDefaults(defineProps<BannerProps>(), {
  image: undefined,
  textColor: BannerTextColor.Black,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const hasWhiteText = computed(() => {
  return props.textColor === BannerTextColor.White
})

const isBigBanner = computed(() => {
  return props.image && props.image.height === 1168
})

const wrapperClasses = computed(() => {
  return [
    tw`relative m-auto overflow-hidden max-w-[2688px]`,
    isBigBanner.value &&
      tw`aspect-[1440/584] max-h-[744px] min-h-[584px] w-full object-contain`,
  ]
})
</script>

<style module>
.blackBlur {
  backdrop-filter: blur(6px);
  background: linear-gradient(180deg, transparent, rgba(15, 17, 23, 0.85));
  filter: blur(6px);
  mask-image: linear-gradient(0deg, #000000 0%, transparent);
  transform: scale(1.1);
}

.blackGradient {
  opacity: 0.3;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1) 120%);
}

.whiteBlur {
  backdrop-filter: blur(6px);
  background: linear-gradient(180deg, transparent, rgba(255, 255, 255, 0.85));
  filter: blur(6px);
  mask-image: linear-gradient(0deg, #000000 0%, transparent);
  transform: scale(1.1);
}

.whiteGradient {
  opacity: 0.3;
  background: linear-gradient(180deg, transparent, rgba(255, 255, 255, 1) 120%);
}
</style>
